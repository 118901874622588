import type { HiringTeamMember } from "~/types/jben/job_post";
import { useTranslation } from "react-i18next";

const HIRING_TEAM_DISPLAY_LIMIT = 3;

const displayName = (member: HiringTeamMember) => {
  if (member.first_name && member.last_name) {
    return `${member.first_name} ${member.last_name}`;
  }

  return member.email;
};

const hiringTeamMembersList = (hiringTeamMembers: HiringTeamMember[]) => {
  const displayableNames = hiringTeamMembers.slice(0, HIRING_TEAM_DISPLAY_LIMIT).map(displayName);
  const extraMemberCount = hiringTeamMembers.length - HIRING_TEAM_DISPLAY_LIMIT;

  return `${displayableNames.join(", ") + (extraMemberCount > 0 ? ", +" + extraMemberCount : "")}`;
};

export const useHiringTeamRenderer = () => {
  const { t } = useTranslation("job_post");

  const buildRecruiterText = (recruiters: HiringTeamMember[]) => {
    if (recruiters.length === 0) {
      return null;
    }

    const descriptor = t("post.recruiter", { count: recruiters.length });
    return `${hiringTeamMembersList(recruiters)} (${descriptor})`;
  };

  const buildHiringManagerText = (hiringManagers: HiringTeamMember[]) => {
    if (hiringManagers.length === 0) {
      return null;
    }

    const descriptor = t("post.hiring_manager", { count: hiringManagers.length });
    return `${hiringTeamMembersList(hiringManagers)} (${descriptor})`;
  };

  return { buildRecruiterText, buildHiringManagerText };
};
